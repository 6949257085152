"use client";
import { useEffect } from 'react';
import Image, { StaticImageData } from 'next/image';

declare global {
  interface Window {
    mdChatClient: string;
  }
}

import iconChat from '@/app/assets/contact/icon-chat.svg';
import iconEmail from '@/app/assets/contact/icon-email.svg';
import iconWhats from '@/app/assets/contact/icon-whatsapp.svg';

export default function ContactGroup() {
  useEffect(() => {
    window.mdChatClient = '84FFAF42BF1C4C05943C78140F6960AC';
    const script = document.createElement('script');
    script.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
    document.body.appendChild(script);
  }, []);

  function startChat() {
    const chatWidgetButton = document.querySelector('.md-chat-widget-btn-open') as HTMLButtonElement;

    if (chatWidgetButton) {
      chatWidgetButton.click();
    }
  }

  function handleEmailClick() {
    window.open('mailto:suporte@embarca.ai', '_blank');
  }

  function handleWhatsappClick() {
    window.open('https://wa.me/+5541997020035', '_blank');
  }

  return (
    <div className="flex flex-wrap gap-4 justify-center py-20 mt-10">
      <ContactCard
        icon={iconChat}
        title="CHAT"
        description="Fale diretamente com um atendente, sobre procedimentos e dúvidas."
        onClick={startChat}
        notRefundable={true}
      />
      <ContactCard
        icon={iconEmail}
        title="ENVIE UMA MENSAGEM"
        description="Tenha um retorno eficiente, envie sua mensagem para o e-mail suporte@embarca.ai"
        onClick={handleEmailClick}
        notRefundable={false}
      />
      <ContactCard
        icon={iconWhats}
        title="WHATSAPP"
        description="Fale com um atendente e tire suas dúvidas."
        onClick={handleWhatsappClick}
        notRefundable={true}
      />
    </div>
  );
}

interface ContactCardProps {
  icon: StaticImageData;
  title: string;
  description: string;
  notRefundable: boolean;
  onClick: () => void;
}

function ContactCard({ icon, title, description, notRefundable, onClick }: ContactCardProps) {
  return (
    <div className="p-8 w-80 h-[22rem] bg-white rounded border text-sm flex flex-col items-center justify-center">
      <div className="mb-[1rem]">
        <Image src={icon} alt={`ícone de ${title}`} height={80} width={80} />
      </div>
      <p className="text-2xl text-center font-bold text-[#4F4F4F] mb-2">{title}</p>
      <p className="text-center text-[#4F4F4F] mb-4">{description}</p>
      <button
        className="bg-primary px-4 py-2 rounded hover:bg-[#FFF176] transition-colors duration-300 ease-in-out"
        onClick={onClick}
      >
        Iniciar atendimento
      </button>
      <p className="mt-4 text-xs">
        <strong>Atendimento de segunda a sexta-feira, das 08h30 às 22h e sábado das 15h45 às 22h, exceto feriados.</strong>
        <br />
        {notRefundable && "Esse canal não realiza cancelamento e reembolso."}
      </p>
    </div>
  );
}
