// URL raiz, feita para redirecionar pra home
export const ROOT_DOMAIN = process.env.NEXT_PUBLIC_ROOT_DOMAIN || "embarca.ai";
// URL base, feita pra montar os link no footer
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || "embarca.ai";
// URL do monolito, também usada pra montar links
export const MONOLITH_LINK = process.env.NEXT_PUBLIC_MONOLITH_LINK || "https://passagens.embarca.ai";
//URL da API do monolito
export const MONOLITH_API_LINK = process.env.NEXT_PUBLIC_MONOLITH_API_LINK || "https://embarcai-sales.herokuapp.com/api/v1";
// cookie LGPD
export const CONSENT_COOKIE = 'consent';
// Auth cookie
export const AUTH_COOKIE = 'embarca-user';
// Fluxo de vendas
export const SALES_FLOW = process.env.NEXT_PUBLIC_SALES_FLOW || "INACTIVE";
export const SALES_FLOW_ACTIVE = SALES_FLOW === "ACTIVE";