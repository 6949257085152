"use client";

import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";

import iconEmbarcaai from "@/app/assets/about-us/icon-embarcaai.svg";
import sandwichMenu from "@/app/assets/about-us/icon-sandwich-menu.svg";
import iconAccount from "@/app/assets/about-us/icon-account.svg";
import iconSignIn from "@/app/assets/about-us/icon-signin.svg";
import iconX from "@/app/assets/about-us/icon-x.svg";
import Link from "next/link";
import { BASE_URL, MONOLITH_LINK } from "@/lib/constants";

export default function EmbarcaAiHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!menuRef.current?.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  // Adicionando a classe 'overflow-clip' ao body quando o menu estiver aberto
  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.classList.add("overflow-clip");
      document.body.classList.add("overflow-clip");
    } else {
      document.documentElement.classList.remove("overflow-clip");
      document.body.classList.remove("overflow-clip");
    }

    // Cleanup ao desmontar o componente
    return () => {
      document.documentElement.classList.remove("overflow-clip");
      document.body.classList.remove("overflow-clip");
    };
  }, [isMenuOpen]);

  return (
    <header
      ref={menuRef}
      className={
        "header absolute items-center bg-gradient-to-b from-black z-20 h-20 w-full"
      }
    >
      {/* mobile */}
      <div
        className="flex md:hidden items-center w-full max-w-screen-xl mx-auto z-10 bg-[#282828] py-8"

      >
        <nav
          className="pl-4 flex flex-end"
          onClick={handleMenuClick}
        >
          <Image
            src={isMenuOpen ? iconX : sandwichMenu}
            alt={
              isMenuOpen
                ? "Ícone de fechar menu"
                : "Ícone de 3 linhas horizontais, uma em cima da outra, representando o menu de opções"
            }
            width={20}
            height={20}
          />
        </nav>
        <div className="flex flex-auto items-center justify-center">
          <Link href="/">
            <Image
              src={iconEmbarcaai}
              priority
              alt="Icon com a logo do embarca.ai"
              width={115}
              height={16}
            />
          </Link>
        </div>
      </div>

      {/* desktop */}
      <div className="min-w-full h-[5rem] md:flex hidden text-xs text-white font-bold justify-between items-center top-0 z-20 max-w-6xl">
        <div className="flex items-center md:ml-[2rem] lg:ml-[10rem]">
          <Link href="https://www.embarca.ai">
            <Image
              src={iconEmbarcaai}
              priority
              alt="Icon com a logo do embarca.ai"
              width={208}
              height={30}
            />
          </Link>
        </div>
        <nav className="flex items-center space-x-4 pr-5">
          <Link href="https://www.embarca.ai/sobre-nos">
            <p className="hover:bg-[#414141] rounded-3xl p-3 transition-all">Sobre nós</p>
          </Link>
          <Link href="https://www.embarca.ai/parceiros">
            <p className="hover:bg-[#414141] rounded-3xl p-3 transition-all">Viações parceiras</p>
          </Link>
          <Link href="https://www.embarca.ai/faq">
            <p className="hover:bg-[#414141] rounded-3xl p-3 transition-all">Precisa de ajuda?</p>
          </Link>
          {isLoggedIn ? (
            <>
              <Link href="#" onClick={() => setIsLoggedIn(false)}>
                <p className="hover:bg-[#414141] rounded-3xl p-3 transition-all">Sair</p>
              </Link>
            </>
          ) : (
            <>
              <Link href={`${MONOLITH_LINK}/sessions/new`} className="flex items-center">
                <Image
                  src={iconAccount}
                  alt="Icon de entrada na conta"
                  width={22}
                  height={22}
                />
              </Link>
              <Link href={`${MONOLITH_LINK}/sessions/new`}>
                <p className="hover:bg-[#414141] rounded-3xl p-3 transition-all">Entrar</p>
              </Link>
            </>
          )}
        </nav>
      </div>

      {isMenuOpen && (
        <nav className="flex flex-col text-xs font-semibold text-gray w-[75%] min-h-full rounded-lg mb-4 z-20">
          <div className="text-left flex-end">
            <div className="flex justify-between bg-gray-5 pl-8">
              <div className="items-center text-[#e6fa01] pt-4">
                <p>Bem-vindo</p>
                <Link href={`${MONOLITH_LINK}/sessions/new`}>
                  <p className="pb-8">Faça seu login</p>
                </Link>
              </div>
              <Link href={`${MONOLITH_LINK}/sessions/new`}>
                <Image
                  className="mr-4 mt-6"
                  src={iconSignIn}
                  alt="Icon de um vetor, indicando local para realizar login"
                  width={18}
                  height={16}
                />
              </Link>
            </div>

            <div className="bg-white px-8 z-10 min-h-screen">
              <Link href={`http${process.env.NODE_ENV === 'production' ? 's': ''}://${BASE_URL}/sobre-nos`}>
                <p className="pb-2 pt-4">Sobre nós</p>
              </Link>
              <hr className="h-px my-2 bg-gray-3 border-0 mr-8"></hr>
              <Link href={`http${process.env.NODE_ENV === 'production' ? 's': ''}://${BASE_URL}/parceiros`}>
                <p className="pb-2 pt-4">Viações Parceiras</p>
              </Link>
              <hr className="h-px my-2 bg-gray-3 border-0 mr-8"></hr>
              <Link href={`http${process.env.NODE_ENV === 'production' ? 's': ''}://${BASE_URL}/faq`}>
                <p className="pb-2 pt-4">Precisa de ajuda?</p>
              </Link>
              <hr className="h-px my-2 bg-gray-3 border-0 mr-8"></hr>
            </div>
          </div>
        </nav>
      )}
    </header>
  );
};
